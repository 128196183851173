<template>
  <j-overlay 
    v-model="opened" 
    content-right
    class="sysenv_workorder_status"
    style="z-index: 6;"
    :id="localId"
  >
    <div class="j_modal_slide__content_wrapper" :class="classWrapper">
      <div class="lnb">
      </div>
      <div class="content">
        <div v-if="!modeNew" class="comment_button_box">
          <a class="btn-comment" title="Comments..." @click="openComment">
            <i class="mdi mdi-comment-text-outline"></i>
          </a>
          <div v-if="item.comments" class="appended-comments">{{ item.comments }}</div>
        </div>
        <a v-ripple class="btn-save" title="Save All" @click="onSave">
          <i class="mdi mdi-content-save-all"></i>
        </a>
        <a v-ripple class="btn-cancel" title="Cancel & Close" @click="onClose">
          <i class="mdi mdi-close"></i>
        </a>

        <div>
          <div class="tab-header">
            <span class="tab-title">Service Request & Work Process</span>
            <span class="tab-sub-title">Requested by Peter Kim, 10-May 2020</span>
          </div>

          <div class="section" style="margin-top: .5rem;">
            <div class="item_info no-margin">
              <span class="item_label">Version History</span>
            </div>
            <div class="item_info">
              <div class="table">
                <div class="table_header">
                  <div class="background"></div>
                  <div class="header_row">
                    <span class="cell version">Ver.</span>
                    <span class="cell reqdate">Req. Date</span>
                    <span class="cell reqtype">Req. Type</span>
                    <span class="cell status">Status</span>
                    <span class="cell closed">Closed Date</span>
                  </div>
                </div>
                <div v-if="item.versionHist && item.versionHist.length > 0" class="table_body">
                  <div 
                    v-for="row in item.versionHist" 
                    class="table_row" 
                    :class="{ selected: row.version == item.version }" 
                    :key="row.index" 
                    @click="getWorkorder(row.version)"
                  >
                    <span class="cell version">{{ row.version }}</span>
                    <span class="cell reqdate">{{ row.reqDate }}</span>
                    <span class="cell reqtype">{{ getWorkTypeName(row.reqType) }}</span>
                    <span class="cell status">{{ getWorkStatusName(row.reqStatus) }}</span>
                    <span class="cell closed">{{ row.completeDate }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="section">
            <div class="item_info">
              <span class="item_label" style="color: #e53935;">Version</span>
              <input v-model="item.version" type="text" class="item_value w30" style="background-color: #f5f5f5; border-radius: 0; border-bottom: solid 1px #e53935; color: #e53935;" />
              <span class="spacer"></span>
              <span class="item_label">Req. Name</span>
              <input v-model="item.reqName" type="text" :class="`item_value main w70${errorClass('reqName')}`" />
            </div>
          </div>
          <div class="section" style="margin-top: 1.5rem;">
            <div class="item_info align-top">
              <div class="item_info vertical">
                <div class="item_info">
                  <span class="item_label">Phase</span>
                  <select v-model="item.phase" :class="`item_value main w40${errorClass('phase')}`" @input="e => onPhaseChanged(e.target.value)">
                    <option v-for="option in phaseOptions" :key="option.index" :value="option.phaseCode">{{ option.phaseName }}</option>
                  </select>
                </div>
                <div class="item_info">
                  <span class="item_label">Group</span>
                  <select v-model="item.group" :class="`item_value main w40${errorClass('group')}`" @input="e => onGroupChanged(e.target.value)">
                    <option v-for="option in groupOptions" :key="option.index" :value="option.groupCode">{{ option.groupName }}</option>
                  </select>
                </div>
                <div class="item_info">
                  <span class="item_label">Item</span>
                  <select v-model="item.item" :class="`item_value main w40${errorClass('item')}`" @input="e => onItemChanged(e.target.value)">
                    <option v-for="option in itemOptions" :key="option.index" :value="option.itemCode">{{ option.itemName }}</option>
                  </select>
                </div>
                <div class="item_info">
                  <span class="item_label">SubItem</span>
                  <select v-model="item.subItem" class="item_value main w40">
                    <option v-for="option in subItemOptions" :key="option.index" :value="option.idx">{{ option.subItemName }}</option>
                  </select>
                </div>
              </div>
              <span class="spacer"></span>
              <div class="item_info align-top">
                <span class="item_label attachment">
                  <a v-ripple class="btn-attachment" title="Attach file(s)" @click="onOpenFile">
                    <i class="mdi mdi-attachment"></i>
                  </a>
                </span>
                <div class="item_value hx4 attachment_wrapper" style="width: 32rem;">
                  <div v-for="file in files" class="attachment" :key="file.idx">
                    <span :class="`date ${file.located}`">{{ file.date }}</span>
                    <span v-if="file.located=='local'" class="file_name unlink" :title="`${file.name} (${calFileSize(file.size)})`">{{ file.name }}</span>
                    <a v-else class="file_name" :title="`${file.name} (${file.size} KB)`" @click="onDownload(file.idx)">{{ file.name }}</a>
                    <span class="remove">
                      <a v-ripple class="btn-delete" title="delete" @click="onDeleteFile(file)">
                        <i class="mdi mdi-trash-can-outline"></i>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section" style="margin-top: 0;">
            <div class="item_info">
              <span class="item_label">Req. By</span>
              <input v-model="item.reqBy" type="text" class="item_value w30" />
              <span class="spacer"></span>
              <span class="item_label">Req. Date</span>
              <div class="item_value w30" style="padding: 0 0;">
                <j-date-picker
                  v-model="item.reqDate"
                  no-border
                  no-default
                  class="item_value"
                  style="margin-top: 0;"
                  :nudge-left="7"
                />
              </div>
              <span class="spacer"></span>
              <span class="item_label">Req. Type</span>
              <select v-model="item.reqType" class="item_value w30">
                <option v-for="option in workTypeOption" :key="option.index" :value="option.value">{{ option.text }}</option>
              </select>
            </div>
            <div class="item_info">
              <span class="item_label">Page Type</span>
              <select v-model="item.pageType" class="item_value w30">
                <option v-for="option in componentDirectionOptions" :key="option.index" :value="option.value">{{ option.text }}</option>
              </select>
              <span class="spacer"></span>
              <span class="item_label">Target Date</span>
              <div class="item_value w30" style="padding: 0 0;">
                <j-date-picker
                  v-model="item.targetDate"
                  no-border
                  no-default
                  class="item_value"
                  style="margin-top: 0;"
                  :nudge-left="7"
                />
              </div>
              <span class="spacer"></span>
              <span class="item_label">Req. Status</span>
              <select v-model="item.reqStatus" class="item_value w30">
                <option v-for="option in workStatusOption" :key="option.index" :value="option.value">{{ option.text }}</option>
              </select>
            </div>
          </div>
          <div class="section" style="margin-top: 0;">
            <div class="item_info no-margin">
              <span class="item_label">Implementation Requirement</span>
            </div>
            <div class="item_info ">
              <textarea v-model="item.requirement" class="item_value w100 multi"></textarea>
            </div>
          </div>
          <div class="section" style="padding: 0 1rem;">
            <div class="progress">
              <div class="colors">
                <div class="color_bar" :style="`width: ${dataMHProg}%; background-color: #fcafc9; border-top-left-radius: 5px; border-bottom-left-radius: 5px; opacity: .55;`"></div>
                <div class="color_bar" :style="`width: ${uxuiMHProg}%; background-color: #feed57;`"></div>
                <div class="color_bar" :style="`width: ${implMHProg}%; background-color: #83d2f5;`"></div>
                <div class="color_bar" :style="`width: ${qcMHProg}%; background-color: #d7e549; border-top-right-radius: 5px; border-bottom-right-radius: 5px;`"></div>
              </div>
              <div :class="`bar float ${isBarStyleComplete('blue', totalProg)}`" :style="`width: ${totalProg}%`"></div>
              <span class="percent">{{ `${totalProg}%` }}</span>
            </div>
          </div>
          <div class="section row" style="margin-top: 0">
            <div class="job_status">
              <div class="title">Data Processing</div>
              <div class="description">
                Service Description<br />
                Database Design<br />
                Data Preprocessing & Core Scripting
              </div>
              <div class="person">
                <span class="icon">
                  <i class="mdi mdi-account-tie"></i>
                </span>
                <input v-model="dataPerson" type="text" class="name" />
              </div>
              <div class="status_wrapper">
                <j-button :class="'type01' + getJobStatusClass(dataStatus)" @click="setNextJobStatus('dataProc', dataStatus)">{{ getJobStatusName(dataStatus) }}</j-button>
              </div>
              <span class="spacer" style="display: block; min-height: 1rem;"></span>
              <div class="date_wrapper">
                <j-date-picker
                  v-model="dataTDate"
                  no-default
                  label="Target Date"
                />
                <j-date-picker
                  v-model="dataCDate"
                  no-default
                  label="Complete Date"
                />
              </div>
              <span class="spacer" style="display: block; min-height: .3rem;"></span>
              <div class="progress_wrapper">
                <div class="text_box">
                  <small class="text_label">M/H</small>
                  <input v-model="dataMHProg" name="mh" type="number" />
                </div>
                <div class="text_box">
                  <span class="text_label">Prog.</span>
                  <input v-model="dataProg" name="progress" type="number" />
                </div>
                <div class="progress">
                  <!-- <span class="percent">{{ dataProg ? dataProg + '%' : '' }}</span> -->
                  <div :class="'bar ' + isBarStyleComplete('pink', dataProg)" :style="'width: ' + (dataProg ? dataProg + '%' : '0') "></div>
                </div>
              </div>
            </div>
            <span class="spacer"></span>
            <div class="job_status">
              <div class="title">UX/UI</div>
              <div class="description">
                Service Planning<br />
                Page & Report Design<br />
                Implementing SVG Chart & Report
              </div>
              <div class="person">
                <span class="icon">
                  <i class="mdi mdi-account-tie"></i>
                </span>
                <input v-model="uxuiPerson" type="text" class="name" />
              </div>
              <div class="status_wrapper">
                <j-button :class="'type01' + getJobStatusClass(uxuiStatus)" @click="setNextJobStatus('uxui', uxuiStatus)">{{ getJobStatusName(uxuiStatus) }}</j-button>
              </div>
              <span class="spacer" style="display: block; min-height: 1rem;"></span>
              <div class="date_wrapper">
                <j-date-picker
                  v-model="uxuiTDate"
                  no-default
                  label="Target Date"
                />
                <j-date-picker
                  v-model="uxuiCDate"
                  no-default
                  label="Complete Date"
                />
              </div>
              <span class="spacer" style="display: block; min-height: .3rem;"></span>
              <div class="progress_wrapper">
                <div class="text_box">
                  <small class="text_label">M/H</small>
                  <input v-model="uxuiMHProg" name="mh" type="number" />
                </div>
                <div class="text_box">
                  <span class="text_label">Prog.</span>
                  <input v-model="uxuiProg" name="progress" type="number" />
                </div>
                <div class="progress">
                  <!-- <span class="percent">36%</span> -->
                  <div :class="'bar ' + isBarStyleComplete('yellow', uxuiProg)" :style="'width: ' + (uxuiProg ? uxuiProg + '%' : '0') "></div>
                </div>
              </div>
            </div>
            <span class="spacer"></span>
            <div class="job_status">
              <div class="title">Implementation</div>
              <div class="description">
                Publishing HTML & Style<br />
                Front-End Scripting<br />
                API Design & Integrating
              </div>
              <div class="person">
                <span class="icon">
                  <i class="mdi mdi-account-tie"></i>
                </span>
                <input v-model="implPerson" type="text" class="name" />
              </div>
              <div class="status_wrapper">
                <j-button :class="'type01' + getJobStatusClass(implStatus)" @click="setNextJobStatus('implement', implStatus)">{{ getJobStatusName(implStatus) }}</j-button>
              </div>
              <span class="spacer" style="display: block; min-height: 1rem;"></span>
              <div class="date_wrapper">
                <j-date-picker
                  v-model="implTDate"
                  no-default
                  label="Target Date"
                />
                <j-date-picker
                  v-model="implCDate"
                  no-default
                  label="Complete Date"
                />
              </div>
              <span class="spacer" style="display: block; min-height: .3rem;"></span>
              <div class="progress_wrapper">
                <div class="text_box">
                  <small class="text_label">M/H</small>
                  <input v-model="implMHProg" name="mh" type="number" />
                </div>
                <div class="text_box">
                  <span class="text_label">Prog.</span>
                  <input v-model="implProg" name="progress" type="number" />
                </div>
                <div class="progress">
                  <!-- <span class="percent">36%</span> -->
                  <div :class="'bar ' + isBarStyleComplete('blue', implProg)" :style="'width: ' + (implProg ? implProg + '%' : '0') "></div>
                </div>
              </div>
            </div>
            <span class="spacer"></span>
            <div class="job_status">
              <div class="title">Quality Control (QC)</div>
              <div class="description">
                Unit & Intergrated Test<br />
                Qualifying Service
              </div>
              <div class="person">
                <span class="icon">
                  <i class="mdi mdi-account-tie"></i>
                </span>
                <input v-model="qcPerson" type="text" class="name" />
              </div>
              <div class="status_wrapper">
                <j-button :class="'type01' + getJobStatusClass(qcStatus)" @click="setNextJobStatus('qc', qcStatus)">{{ getJobStatusName(qcStatus) }}</j-button>
              </div>
              <span class="spacer" style="display: block; min-height: 1rem;"></span>
              <div class="date_wrapper">
                <j-date-picker
                  v-model="qcTDate"
                  no-default
                  label="Target Date"
                  :nudge-left="140"
                />
                <j-date-picker
                  v-model="qcCDate"
                  no-default
                  label="Complete Date"
                  :nudge-left="140"
                />
              </div>
              <span class="spacer" style="display: block; min-height: .3rem;"></span>
              <div class="progress_wrapper">
                <div class="text_box">
                  <small class="text_label">M/H</small>
                  <input v-model="qcMHProg" name="mh" type="number" />
                </div>
                <div class="text_box">
                  <span class="text_label">Prog.</span>
                  <input v-model="qcProg" name="progress" type="number" />
                </div>
                <div class="progress">
                  <!-- <span class="percent">{{ dataProg ? dataProg + '%' : '' }}</span> -->
                  <div :class="'bar ' + isBarStyleComplete('green', qcProg)" :style="'width: ' + (qcProg ? qcProg + '%' : '0') "></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <input 
      type="file" 
      multiple
      id="wo_files" 
      name="wo_files"
      style="display:none;"
      @change="onHandleFiles"
    >
    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :titleDescription="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :buttonText="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>

  </j-overlay>
</template>
<script>
import '@/assets/stylus/ui/component/_sysenv.workorder.status.modal.styl'

import { mapState } from 'vuex'

import * as d3 from 'd3'
import __M from 'moment'
import __C from '@/primitives/_constant_'
import _Data from '@/primitives/_workOrderDefault'
import { SystemService } from "@/services"
import { SafeIdMixin } from '@/mixins/safeid.mixin'
import Loading from '@/mixins/loading.mixin'
import Comment from '@/mixins/comment.mixin'
import Url from '@/services/api/request.url'
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-modal-slide-component---workorder',
  mixins: [
    Comment,
    Loading,
    SafeIdMixin,
    StaticOptions
  ],
  props: {
    keyValue: null,
    version: null,
    value: null,
  },
  data: () => ({
    systemService: null,

    item: {},
    prevItem: {},
    date: null,

    phaseOptions: [],
    groupOptions: [],
    itemOptions: [],
    subItemOptions: [],
    
    classWrapper: 'closed',
    opened: false,
    error: '',
    localFiles: [],
    required: ['reqName', 'phase', 'group', 'item'],
    jobName: {
      'dataProc': 'Data Processing',
      'uxui': 'UI/UX',
      'implement': 'implementation',
      'qc': 'Quality Control',
    },
    errors: [],
    progress: {
      complete: 0,
      total: 0
    },

    msgOpen: false,
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    yes: () => {},
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),

    __C_() { return __C },

    today() { return __M().format('YYYY-MM-DD') },
    files() { 
      let files_ = [];
      (this.item.files || []).forEach(f => {
        files_.push({ located: 'remote', ...f })
      })
      this.localFiles.forEach(f => {
        files_.push({ 
          located: 'local',
          date: this.today,
          name: f.name,
          size: Math.round(f.size / 1024)
        })
      })

      return files_.sort((a, b) => (a.date < b.date) ? 1 : ((a.date === b.date) ? ((a.name > b.name) ? 1 : -1) : -1) )
    },

    modeView() { return this.mode == __C.FORM.EDIT_MODE_VIEW },
    modeNew() { return this.formMode == __C.FORM.EDIT_MODE_NEW },
    modeMod() { return this.formMode == __C.FORM.EDIT_MODE_MOD },
    formMode() {
      if (this.keyValue) return __C.FORM.EDIT_MODE_MOD
      else return __C.FORM.EDIT_MODE_NEW
    },

    dataPerson: {
      get() { return this.item.dataProc ? this.item.dataProc.personName : '' },
      set(val) { 
        this.setDataProc()
        this.item.dataProc.personName = val
      }
    },
    dataStatus: {
      get() { return this.item.dataProc ? this.item.dataProc.status : '' },
      set(val) { 
        this.setDataProc()
        this.item.dataProc.status = val
      }
    },
    dataTDate: {
      get() { return this.item.dataProc ? this.item.dataProc.targetDate : '' },
      set(val) { 
        this.setDataProc()
        this.item.dataProc.targetDate = val
      }
    },
    dataCDate: {
      get() { return this.item.dataProc ? this.item.dataProc.completeDate : '' },
      set(val) { 
        this.setDataProc()
        this.item.dataProc.completeDate = val
      }
    },
    dataMHProg: {
      get() { return this.item.dataProc ? this.item.dataProc.mhProg : 0 },
      set(val) { 
        this.setDataProc()
        this.item.dataProc.mhProg = val
      }
    },
    dataProg: {
      get() { return this.item.dataProc ? this.item.dataProc.prog : 0 },
      set(val) { 
        this.setDataProc()
        this.item.dataProc.prog = val
      }
    },

    uxuiPerson: {
      get() { return this.item.uxui ? this.item.uxui.personName : '' },
      set(val) { 
        this.setUXUI()
        this.item.uxui.personName = val
      }
    },
    uxuiStatus: {
      get() { return this.item.uxui ? this.item.uxui.status : '' },
      set(val) { 
        this.setUXUI()
        this.item.uxui.status = val
      }
    },
    uxuiTDate: {
      get() { return this.item.uxui ? this.item.uxui.targetDate : '' },
      set(val) { 
        this.setUXUI()
        this.item.uxui.targetDate = val
      }
    },
    uxuiCDate: {
      get() { return this.item.uxui ? this.item.uxui.completeDate : '' },
      set(val) { 
        this.setUXUI()
        this.item.uxui.completeDate = val
      }
    },
    uxuiMHProg: {
      get() { return this.item.uxui ? this.item.uxui.mhProg : 0 },
      set(val) { 
        this.setUXUI()
        this.item.uxui.mhProg = val
      }
    },
    uxuiProg: {
      get() { return this.item.uxui ? this.item.uxui.prog : 0 },
      set(val) { 
        this.setUXUI()
        this.item.uxui.prog = val
      }
    },

    implPerson: {
      get() { return this.item.implement ? this.item.implement.personName : '' },
      set(val) { 
        this.setImpl()
        this.item.implement.personName = val
      }
    },
    implStatus: {
      get() { return this.item.implement ? this.item.implement.status : '' },
      set(val) { 
        this.setImpl()
        this.item.implement.status = val
      }
    },
    implTDate: {
      get() { return this.item.implement ? this.item.implement.targetDate : '' },
      set(val) { 
        this.setImpl()
        this.item.implement.targetDate = val
      }
    },
    implCDate: {
      get() { return this.item.implement ? this.item.implement.completeDate : '' },
      set(val) { 
        this.setImpl()
        this.item.implement.completeDate = val
      }
    },
    implMHProg: {
      get() { return this.item.implement ? this.item.implement.mhProg : 0 },
      set(val) { 
        this.setImpl()
        this.item.implement.mhProg = val
      }
    },
    implProg: {
      get() { return this.item.implement ? this.item.implement.prog : 0 },
      set(val) { 
        this.setImpl()
        this.item.implement.prog = val
      }
    },

    qcPerson: {
      get() { return this.item.qc ? this.item.qc.personName : '' },
      set(val) { 
        this.setQC()
        this.item.qc.personName = val
      }
    },
    qcStatus: {
      get() { return this.item.qc ? this.item.qc.status : '' },
      set(val) { 
        this.setQC()
        this.item.qc.status = val
      }
    },
    qcTDate: {
      get() { return this.item.qc ? this.item.qc.targetDate : '' },
      set(val) { 
        this.setQC()
        this.item.qc.targetDate = val
      }
    },
    qcCDate: {
      get() { return this.item.qc ? this.item.qc.completeDate : '' },
      set(val) { 
        this.setQC()
        this.item.qc.completeDate = val
      }
    },
    qcMHProg: {
      get() { return this.item.qc ? this.item.qc.mhProg : 0 },
      set(val) { 
        this.setQC()
        this.item.qc.mhProg = val
      }
    },
    qcProg: {
      get() { return this.item.qc ? this.item.qc.prog : 0 },
      set(val) { 
        this.setQC()
        this.item.qc.prog = val
      }
    },

    totalProg() {
      return (
        this.dataMHProg * (this.dataProg / 100) +
        this.uxuiMHProg * (this.uxuiProg / 100) +
        this.implMHProg * (this.implProg / 100) +
        this.qcMHProg * (this.qcProg / 100)
      )
    }
  },
  watch: {
    value(val) {
      this.localFiles = []

      if(val) {
        if(this.modeNew) {
          this.item = _Data
        } else {
          this.getWorkorder()
        }
        this.onOpen()

      } else {
        this.onClose()
      }
    }
  },
  created() {
    this.localId = this.id || 'j-workorder-status__' + this.safeId('')
    this.systemService = new SystemService()
  },
  mounted() {
    this.systemService.getNavPhase(res => {
      if (!res) this.phaseOptions = []
      else this.phaseOptions = [
        { phaseCode: 'NA', phaseName: 'None' },
        ...res
      ]
    })
  },
  methods: {
    onPhaseChanged(phaseCode) {
      this.groupOptions = []
      if(phaseCode == '') return

      this.systemService.getNavGroup(phaseCode, res => {
        if (res) this.groupOptions = [
          { groupCode: 'NA', groupName: 'None' },
          ...res
        ]
      })
    },
    onGroupChanged(groupCode) {
      this.itemOptions = []
      if(groupCode == '') return

      this.systemService.getNavItem(
        res => {
          if(res) this.itemOptions = [
            { itemCode: 'NA', itemName: 'None' },
            ...res
          ]
        },
        this.item.phase,
        groupCode
      )
    },
    onItemChanged(itemCode) {
      this.subItemOptions = [
        {
          idx: 0,
          subItemName: 'None',
        }
      ]
      if (itemCode == '') return
      if (!this.item.phase || !this.item.item) return

      this.systemService.getNavSubItem(
        res => {
          if (res) this.subItemOptions = [ ...this.subItemOptions, ...res ]
        },
        this.item.phase,
        this.item.item
      )
    },
    onOpen() {
      this.opened = true
      setTimeout(() => { 
        this.classWrapper = 'opened' 
        d3.select(`#${this.localId}`).select('.content').transition().delay(300).duration(200).style('opacity', 1)
      })
    },
    onClose() {
      d3.select(`#${this.localId}`).select('.content').transition().duration(150).style('opacity', 0)

      setTimeout(() => { 
        this.classWrapper = 'closed'
      }, 150)
      setTimeout(() => { 
        this.opened = false
        this.$emit('input', false) 
      }, 450)
    },
    onDownload(fileId) {
      var iframe = document.createElement('iframe')
      document.body.appendChild(iframe)
      let params = new URLSearchParams()
      params.append('token', this.account.token)
      iframe.src = `${Url.download}/${fileId}?${params.toString()}`

      // setTimeout(() => {
      //   iframe.parentNode.removeChild(iframe)
      // }, 10000);
    },
    onHandleFiles(e) {
      if(!e || !e.target) return
      this.appendFiles(Object.values(e.target.files).filter(f_ => !!f_))
      document.querySelector(`input[name='wo_files']`).value = null
    },
    onOpenFile() {
      document.querySelector(`input[name='wo_files']`).click()
    },
    onDeleteFile(file) {
      if(file.located == 'local') {
        let i = this.localFiles.findIndex(f => f.name == file.name)
        if(i < 0) return

        this.localFiles[i] = null
        this.localFiles = this.localFiles.filter(f => !!f)
        return
      }

      this.yes = () => {
        this.msgOpen = false
        this.systemService.delWorkorderFile(file.idx).then(() => {
          this.systemService.getWorkorderFiles(this.keyValue, this.item.version).then(res => {
            
            this.item.files = res
          })
        })
        this.yes = () => {}
      }

      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Action Approval"
      this.msgInfo.message = "Do you want to delete this attachment?"
      this.msgInfo.button = [true, false, true]
      this.msgInfo.buttonText = ['Yes', 'No', 'Cancel']
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    onSaveFile(idx=null) {
      if(this.localFiles.length === 0)
        return new Promise(resolve => { resolve(-1) })

      let data_ = {
        idx: idx || this.keyValue,
        version: this.item.version,
        files : this.localFiles.map(f => {
          let fileNameParts = f.name.split('.')
          return {
            name      : f.name,
            mimeType  : f.type,
            fileType  : f.type.split('/')[0],
            ext       : fileNameParts[fileNameParts.length-1],
            dataUrl   : '',
            size      : Number(f.size),
            dimention : '',
          }
        })
      }
      let config = {
        onUploadProgress: e => {
          this.progress = {
            complete: e.loaded,
            total: e.total
          }
        }
      }
      let params = new FormData()
      params.append("data", JSON.stringify(data_))
      this.localFiles.forEach(f => { params.append("files", f) })

      return this.systemService.putWorkorderFiles(params, config)
    },
    onSave() {
      if(!this.validateForm()) return

      let order_ = JSON.parse(JSON.stringify(this.item))
      let postSave = () => {
        this.localFiles = []
        this.progress = { complete: 0, total: 0 }
        this.getWorkorder(this.item.version)
        this.$emit('saved')
      }
      
      if(this.modeNew) this.systemService.putWorkorder(order_).then(idx => {
          this.onSaveFile(idx).then(() => { postSave() })
      }); else this.systemService.updWorkorder(order_).then(idx => {
        this.onSaveFile().then(() => { postSave() })
      })
    },

    appendFiles(_files) {
      _files.forEach(f => {
        if(this.localFiles.find(f__ => f__.name == f.name)) return

        f.date = this.today
        this.localFiles.push(f)
      })
    },
    calFileSize(size) {
      return (size ? Math.round(size/1024).toLocaleString() : 0) + ' KB'
    },
    isBarStyleComplete(color, prog) {
      return prog == 100 ? 'gray' : color
    },
    getJobStatusName(code) { 
      let status = this.jobStatusOption.find(s => s.value == code)
      return status ? status.text : ''
    },
    getJobStatusClass(code) { 
      let status = this.jobStatusOption.find(s => s.value == code)
      return status ? ' ' + status.class : ''
    },
    getWorkorder(version=null) {
      this.systemService.getWorkorder(this.keyValue, version||this.version).then(res => {
        this.item = res
        this.onPhaseChanged(this.item.phase)
        this.onGroupChanged(this.item.group)
        this.onItemChanged(this.item.item)
      })
    },
    openComment() {
      this.setCommentProps({ refCode: `WorkorderStatus_${this.keyValue}` })
      this.onCommentOpen()
    },
    setNextJobStatus(type, code) {
      let index_ = this.jobStatusOption.findIndex(s => s.value == code)
      index_ = index_ < 0 || index_ == 3 ? 0 : (typeof index_ != 'undefined' ? index_ + 1 : 0)
      
      this.item[type].completeDate = ''

      if(index_ === 0) {
        this.item[type].prog = 0

      } else if(index_ == 3) {
        this.item[type].prog = 100
        this.item[type].targetDate = this.item[type].targetDate || this.today
        this.item[type].completeDate = this.today
      }

      this.item[type].status = this.jobStatusOption[index_].value
      this.item.jobLog += `${this.item.jobLog?'\n':''}[${__M().format('YYYY-MM-DD HH:mm:ss')}#V:${this.item.version}] '${this.jobName[type]}' has changed to '${this.getJobStatusName(this.item[type].status)}' by ${this.account.userName}'` 
    },
    setDataProc() {
      if(this.item.dataProc) return
      this.item.dataProc = {}
    },
    setUXUI() {
      if(this.item.uxui) return
      this.item.uxui = {}
    },
    setImpl() {
      if(this.item.implement) return
      this.item.implement = {}
    },
    setQC() {
      if(this.item.qc) return
      this.item.qc = {}
    },

    errorClass(name) {
      if(this.errors.includes(name)) return ' required'
      return ''
    },
    validateForm() {
      let errors_ = []
      this.required.forEach(name => {
        if(!this.item[name]) errors_.push(name)
      })

      this.errors = errors_
      return errors_.length === 0
    }
  }
}
</script>
