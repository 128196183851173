<template>
  <div>
    <div class="j_datagrid__header type01">
      <div class="header__title">
        <h5>
          Workorder Status
          <small>Progress of the client's request.</small>
        </h5>
      </div>

      <div class="header__right">
        <v-flex>
          <button class="jcon_add" @click="onAdd"></button>
          <div class="j_search type01">
            <input v-model="search" type="search" name="q" placeholder="Filter and search" />
            <button type="submit">
              <v-icon>mdi-magnify</v-icon>
            </button>
          </div>
        </v-flex>
      </div>
    </div>

    <j-data-grid
      v-model="selected"
      class="chart"
      hide-actions
      :headers="headers"
      :items="items"
      :search="search"
    >
      <template v-slot:items="props">
        <tr :active="props.selected" @click="propStatus(props)">
          <td style="text-align: center">{{ props.item.no }}</td>
          <td>{{ props.item.reqBy }}</td>
          <td>{{ getWorkTypeName(props.item.reqType) }}</td>
          <td>{{ props.item.reqName }}</td>
          <td>{{ getWorkStatusName(props.item.reqStatus) }}</td>
          <td>{{ props.item.phaseName }}</td>
          <td>{{ props.item.groupName }}</td>
          <td>{{ props.item.itemName }}</td>
          <td style="text-align: center">{{ props.item.reqDate }}</td>
          <td style="text-align: center">{{ props.item.targetDate }}</td>
          <td style="text-align: center"></td>
          <td style="text-align: center">{{ props.item.qc.completeDate }}</td>
          <td style="text-align: center"></td>
          <td style="text-align: center">{{ props.item.dataProc.personName }}</td>
          <td style="text-align: center">{{ props.item.uxui.personName }}</td>
          <td style="text-align: center">{{ props.item.implement.personName }}</td>
          <td style="text-align: center">{{ props.item.qc.personName }}</td>
          <td style="text-align: center">{{ props.item.version }}</td>
          <td></td>
        </tr>
      </template>
    </j-data-grid>

    <slide-modal
       v-model="modalOpened"
       :key-value="itemKey"
       :version="version"
       @saved="onSaved"
    >
    </slide-modal>

  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapMutations } from 'vuex'
import { SystemService } from "@/services"
import SlideModal from './modal/SysenvWorkorderStatus.modal'
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'sysenv-workorder-status',
  mixins: [
    StaticOptions
  ],
  components: {
    SlideModal,
  },
  data: () => ({
    systemService: null,

    headers: [
      { type: 'conf', text: 'No', align: 'center', value: 'no', sortable: false, width: 30, },
      { type: 'text', text: 'Req. By', align: 'left', value: 'reqBy', sortable: false, width: 100, },
      { type: 'text', text: 'Type', align: 'left', value: 'reqTypeName', sortable: false, width: 100, },
      { type: 'text', text: 'Name', align: 'left', value: 'reqName', sortable: false, width: 220, },
      { type: 'text', text: 'Status', align: 'left', value: 'reqStatusName', sortable: false, width: 80, },
      { type: 'text', text: 'M. Phase', align: 'left', value: 'phaseName', sortable: false, width: 120, },
      { type: 'text', text: 'M. Group', align: 'left', value: 'groupName', sortable: false, width: 200, },
      { type: 'text', text: 'M. Item', align: 'left', value: 'itemName', sortable: false, width: 170, },
      { type: 'text', text: 'Req. Date', align: 'center', value: 'reqDate', sortable: false, width: 80, },
      { type: 'text', text: 'Target Date', align: 'center', value: 'targetDate', sortable: false, width: 80, },
      { type: 'text', text: 'Float', align: 'center', value: '', sortable: false, width: 30, },
      { type: 'text', text: 'Compl. Date', align: 'center', value: '', sortable: false, width: 80, },
      { type: 'text', text: 'Earned', align: 'center', value: '', sortable: false, width: 30, },
      { type: 'text', text: 'Data Proc.', align: 'center', value: '', sortable: false, width: 85 },
      { type: 'text', text: 'UX/UI.', align: 'center', value: '', sortable: false, width: 85 },
      { type: 'text', text: 'Implementing.', align: 'center', value: '', sortable: false, width: 85 },
      { type: 'text', text: 'QC', align: 'center', value: '', sortable: false, width: 85 },
      { type: 'text', text: 'Version', align: 'center', value: 'version', sortable: false, width: 70 },
      { type: 'text', text: '', align: 'center', value: 'version', sortable: false },
    ],
    items: [],

    search: '',
    modalOpened: false,
    itemKey: null,
    version: null,
    selected: [],
    selectedItem: {},
    selectedPrevItems: [],
  }),
  computed: {
  },
  watch: {
    modalOpened(val) {
      this.setModalOpened(val)
    }
  },
  created() {
    this.systemService = new SystemService()
  },
  mounted() {
    this.getWorkorders()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setChild', 'setModalOpened'
    ]),

    onAdd() {
      this.itemKey = null
      this.version = null
      this.modalOpened = true
    },
    onSaved() {
      this.getWorkorders()
    },

    propStatus(props) {
      this.selectedItem = Object.assign({}, props.item)
      this.itemKey = this.selectedItem.idx
      this.version = this.selectedItem.version

      if (this.selectedPrevItems.length > 0) {
        if (this.selectedPrevItems[0].idx == props.item.idx) {
          this.modalOpened = true
          return
        } else {
          let _selected = props.item
          this.selected = [_selected]
        }
      } else {
        props.selected = !props.selected
      }

      this.selectedPrevItems = [...this.selected]
      this.modalOpened = true
    },
    getWorkorders() {
      this.systemService.getWorkorders('NA', 'NA').then(res => {
        this.items = res
      })
    }
  }
}
</script>
