export default {
  reqName: '',
  phase: '',
  group: '',
  item: '',
  subItem: 0,
  versionHist: [],
  requirement: '',
  jobLog: '',

  version: '0.1',
  reqType: 'New',
  reqStatus: 'plan',
  reqBy: '',
  reqDate: '',
  pageType: 'N/A',
  targetDate: '',
  files: [],
  dataProc: {
    personName: '',
    status: 'not',
    targetDate: '',
    completeDate: '',
    mhProg: 25,
    prog: 0
  },
  uxui: {
    personName: '',
    status: 'not',
    targetDate: '',
    completeDate: '',
    mhProg: 55,
    prog: 0
  },
  implement: {
    personName: '',
    status: 'not',
    targetDate: '',
    completeDate: '',
    mhProg: 15,
    prog: 0
  },
  qc: {
    personName: '',
    status: 'not',
    targetDate: '',
    completeDate: '',
    mhProg: 5,
    prog: 0
  },
}